<template>
  <div>
    <b-table
      sticky-header="550px"
      responsive="xl"
      show-empty
      :busy="isLoading"
      hover
      :isLoading="isLoading"
      :fields="fields"
      :items="discounts"
    >
      <template #head()="row">
        <div class="text-nowrap">
          {{ row.label }}
        </div>
      </template>
      <template #cell(no)="row">
        {{ (currentpage - 1) * perpage + row.index + 1 }}
      </template>
      <template #cell(name)="row">
        <div @click="actionEdit(row.item)" class="hoverEdit">
          {{ row.item.name ? row.item.name : '' }}
        </div>
      </template>
      <template #cell(discount_type)="row">
        <div>
          <span
            v-if="row.item.discount_type === 1"
            class="badge badge-info p-1"
          >
            {{ promotionType(row.item.discount_type) }}
          </span>
          <span
            v-else-if="row.item.discount_type === 2"
            class="badge badge-secondary p-1"
          >
            {{ promotionType(row.item.discount_type) }}
          </span>
          <span
            v-else-if="row.item.discount_type === 3"
            class="badge badge-success p-1"
          >
            {{ promotionType(row.item.discount_type) }}
          </span>
          <span
            v-else-if="row.item.discount_type === 4"
            class="badge badge-secondary p-1"
          >
            {{ promotionType(row.item.discount_type) }}
          </span>
          <span
            v-else-if="row.item.discount_type === 5"
            class="badge badge-warning p-1"
          >
            {{ promotionType(row.item.discount_type) }}
          </span>
        </div>
      </template>
      <template #cell(valid_from)="row">
        <div style="width:10em;">
          {{ formatDate(row.item.valid_from) }}
        </div>
      </template>
      <template #cell(valid_to)="row">
        <div style="width:10em;">
          {{ formatDate(row.item.valid_to) }}
        </div>
      </template>
      <template #cell(discount_rule)="row">
          {{ discountRuleStr[row.item.discount_rule] }}
      </template>
      <template #cell(predefined_group)="row">
        <div v-if="row.item.predefined_group">
          {{ predefinedGroup(row.item.predefined_group) }}
        </div>
        <div v-else>
          <div @click="actionSpecified(row.item)" class="hoverEdit">
            Specified Offer
          </div>
        </div>
      </template>
      <template #cell(is_active)="row">
        <div
          v-if="row.item.is_active == true"
          @click="actionDeactive(row.item.id)"
        >
          <i
            class="fa fa-check fa-lg fa-beat text-primary hover-check fa-hover-hidden"
          ></i>
          <i
            class="fa fa-times fa-lg fa-beat text-danger hover-times fa-hover-show fa-2x"
          ></i>
        </div>
        <div
          v-if="row.item.is_active == false"
          @click="actionReactive(row.item.id)"
        >
          <i
            class="fa fa-check fa-lg fa-beat text-primary hover-check fa-hover-show fa-2x"
          ></i>
          <i
            class="fa fa-times fa-lg fa-beat text-danger hover-times fa-hover-hidden"
          ></i>
        </div>
      </template>
      <template #cell(status)="row">
        <div
          v-if="
            stillActive(row.item.valid_from, row.item.valid_to) &&
              row.item.is_active
          "
          class="badge badge-info p-1"
        >
          Promo Currently Active
        </div>
        <div
          v-else-if="
            stillActive(row.item.valid_from, row.item.valid_to) &&
              row.item.is_active
          "
          class="badge badge-danger p-1"
        >
          Promo Already Expired
        </div>
        <div
          v-else-if="
            formatDate(row.item.valid_to) >= datenow && row.item.is_active
          "
          class="badge badge-warning p-1"
        >
          Promo Is Not Yet Active
        </div>
        <div v-else-if="row.item.is_active == false">
          <div class="badge badge-danger p-1">
            Promo Inactive
          </div>
        </div>
      </template>
      <template #cell(created)="row">
        <div style="width: 10em;">
          {{ formatDate(row.item.created) }}
        </div>
      </template>

      <template v-slot:table-busy>
        <div class="text-center text-dark my-2">
          <b-spinner
            class="align-middle"
            variant="dark"
            type="grow"
            label="Loading .."
          >
          </b-spinner>
          Loading ..
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import { DiscountEditPath, DiscountSpecified } from '../../router/marketing';
import constant from '../../store/constant';

const {
  OFFER_DISCOUNT_RULE,
} = constant;
const discountRuleStr = Object.fromEntries(
  Object.entries(OFFER_DISCOUNT_RULE)
    .map(val => ([val[1], val[0]]))
);

export default {
  name: 'Discounts',
  props: {
    discounts: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    currentpage: {
      type: Number,
      required: true,
    },
    perpage: {
      type: Number,
      required: true,
    },
    Deactivate: {
      type: Function,
      required: true,
    },
    Reactivate: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      datenow: moment().format('YYYY-MM-DD  HH:mm:ss'),
      fields: [
        {
          key: 'no',
          label: 'No',
        },
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'discount_type',
          label: 'Type',
          thClass: 'text-capitalize',
          tdClass: 'text-capitalize',
        },
        {
          key: 'valid_from',
          label: 'Valid From',
        },
        {
          key: 'valid_to',
          label: 'Valid To',
          variant: 'info',
        },
        {
          key: 'discount_rule',
        },
        {
          key: 'discount_idr',
          label: 'Discount',
        },
        {
          key: 'is_active',
          label: 'Active',
        },
        {
          key: 'predefined_group',
          label: 'Predefined Group',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'created',
          label: 'Created',
        },
      ],
      discountRuleStr,
    };
  },
  methods: {
    ...mapActions('discounts', ['reactiveDiscount', 'deactiveDiscount']),

    promotionType(key) {
      const objPromotionType = {
        1: 'Discount Offers',
        2: 'Discount Orders',
        3: 'Discount payment gateway offer',
        4: 'Discount paytment gateway order',
        5: 'Discount Code',
      };
      return objPromotionType[key] ?? 'Unknown';
    },
    predefinedGroup(key) {
      const objPredefinedGroup = {
        1: 'All Magazine',
        2: 'All Book',
        3: 'All Newspaper',
        4: 'All Offers',
        5: 'All Single Magazine and Book',
        6: 'All Subscriptions Magazine and Newspapers',
        7: 'Magazine Single Only',
        8: 'Magazine Subscription Only',
        9: 'All Magazine and Newspapers',
        10: 'All Book In Vendors',
        11: 'All Magazine In Vendors',
        12: 'All Newspaper In Vendors',
      };
      return objPredefinedGroup[key] ?? 'Unknown';
    },
    formatDate(tgl) {
      return new Date(tgl).toString();
    },
    stillActive(validFrom, validTo) {
      const unixValidFrom = moment(validFrom).unix();
      const unixValidTo = moment(validTo).unix();
      if (
        moment(unixValidFrom).isBefore(moment().unix()) &&
        moment(unixValidTo).isAfter(moment().unix())
      ) {
        return true;
      }
    },
    mapUrl(item) {
      if (item) {
        return DiscountEditPath.name;
      }
    },
    specifiedUrl(item) {
      if (item) {
        return DiscountSpecified.name;
      }
    },
    actionEdit(item) {
      this.$router.push({
        name: this.mapUrl(item),
        params: {
          id: item.id,
        },
      });
    },
    actionSpecified(item) {
      this.$router.push({
        name: this.specifiedUrl(item),
        params: {
          id: item.id,
        },
      });
    },
    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: 'true',
        position: 'top-end',
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
    actionDeactive(idUser) {
      this.$swal({
        title: 'Are you sure?',
        text: 'You want Deactivate this Discount ?!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Deactivate!',
      }).then((result) => {
        if (result.isConfirmed) {
          this.Deactivate({
            id: idUser,
            is_active: false,
          });
        }
      });
    },
    actionReactive(idUser) {
      this.$swal({
        title: 'Are you sure?',
        text: 'You want Reactivate this Discount ?!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Reactivate!',
      }).then((result) => {
        if (result.isConfirmed) {
          this.Reactivate({
            id: idUser,
            is_active: true,
          });
        }
      });
    },
  },
};
</script>
<style lang="css" scoped>
.swal2-input[type='number'] {
  max-width: 20em !important;
}

.hoverEdit {
  cursor: pointer;
}

.hoverEdit:hover {
  color: #2596be;
}

* > .fa.fa-hover-show,
*:hover > .fa.fa-hover-hidden {
  display: none;
}

*:hover > .fa.fa-hover-show {
  display: inline-block;
}
</style>
