<template>
  <b-card class="mt-2 discount">
    <b-col lg="12">
      <b-row v-if="isError" class="justify-content-center mt-2">
        <b-col lg="10">
          <b-alert variant="danger" show dismissible>
            Opps .. Something is wrong
          </b-alert>
        </b-col>
      </b-row>

      <div class="form-group row">
        <div class="col-md-6">
          <b-form-input
            type="search"
            v-model="filter"
            placeholder="Promo HUT Gramedia ..."
          ></b-form-input>
          <span v-if="typing"> <em> Mencari .. </em> </span>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3">
          <multiselect
            v-model="discountType"
            label="text"
            track-by="text"
            placeholder="select discount type"
            open-direction="bottom"
            :options="discountTypeOption"
            :searchable="true"
            :close-on-select="true"
          >
            <span slot="noResult">
              Oops! No elements found. Consider changing the search query.
            </span>
          </multiselect>
        </div>
        <div class="col-sm-3">
          <multiselect
            v-model="discountStatus"
            label="text"
            track-by="text"
            placeholder="select discount status"
            open-direction="bottom"
            :options="discountStatusOption"
            :searchable="true"
            :close-on-select="true"
          >
            <span slot="noResult">
              Oops! No elements found. Consider changing the search query.
            </span>
          </multiselect>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-auto">
          <div class="form-group row">
            <span class="col-md-auto">Valid From </span>
            <v-date-picker v-model="range" mode="date" :masks="masks" is-range>
              <template v-slot="{ inputValue, inputEvents, isDragging }">
                <div class="rows">
                  <input
                    class="flex-grow pl-6 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                    :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                    :value="inputValue.start"
                    v-on="inputEvents.start"
                  />

                  <span class="p-2">to</span>

                  <input
                    class="flex-grow pl-6 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                    :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                    :value="inputValue.end"
                    v-on="inputEvents.end"
                    :max-date="new Date()"
                  />
                </div>
              </template>
            </v-date-picker>
          </div>
        </div>
        <div class="col-md-auto">
          <button class="btn btn-primary" @click="searchByValidTo">
            <i class="fa fa-search"></i> Filter
          </button>
          <b-button
            type="button"
            variant="dark"
            class="ml-2"
            @click="asyncClearFind"
            >Clear</b-button
          >
        </div>
      </div>
      <b-row class="justify-content-end">
        <div class="col-md-auto">
          <button class="btn btn-sm btn-default" @click="actionRefresh">
            <i class="fa fa-refresh"></i>
          </button>
        </div>
        <div class="col-md-auto">
          <router-link
            class="btn btn-primary mb-1"
            :to="{ path: `/discount-add` }"
          >
            <i class="fa fa-plus"></i>
          </router-link>
        </div>
        <div class="col-sm-auto">
          Count :
          <strong class="pl-2">
            {{ formatPriceBasic(totalRows) }}
          </strong>
        </div>
      </b-row>
      <div class="table-responsesive">
        <TableDiscounts
          :currentpage="currentPage"
          :perpage="perPage"
          :discounts="discounts"
          :isLoading="isLoading"
          :Deactivate="Deactivate"
          :Reactivate="Reactivate"
        />
      </div>
      <b-row v-if="totalRows / perPage > 1" class="justify-content-start ml-1">
        <b-col lg="3">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import TableDiscounts from '../../components/discount/discounts-offer.vue';
import constant from '../../store/constant';
import Utils from '../../plugins/util';
const DISCOUNT_TYPES = Object.entries(constant.DISCOUNT_TYPES).map((item) => {
  return {
    text: item[0],
    value: item[1],
  }
});
const discountStatuses = [
  {
    text: 'Currently Active',
    value: 1,
  },
  {
    text: 'Not Yet Active',
    value: 2,
  },
  {
    text: 'Inactive',
    value: 3,
  },
]
export default {
  name: 'discount',
  components: {
    TableDiscounts,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      currentFrom: null,
      currentTo: null,
      filter: null,
      isLoadingSearch: false,
      isLoadingBtnSearch: false,
      debounce: null,
      range: {
        // start: moment().subtract(1, 'month').format('YYYY-MM-DD'),
        // end: moment().format('YYYY-MM-DD'),
        start: null,
        end: null,
      },
      masks: {
        input: 'YYYY-MM-DD',
      },
      typing: false,
      discountTypeOption: [...DISCOUNT_TYPES],
      discountType: null,
      discountStatusOption: [...discountStatuses],
      discountStatus: null,
    };
  },
  watch: {
    currentPage: function() {
      this.actionGetDiscounts();
    },

    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Discounts | Content Management System Ebooks Gramedia.com';
      },
    },
    errorMessage: function() {
      if (!this.errorMessage) return;
      this.alertMsg(this.errorMessage, 'error');
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.discounts.isLoading,
      isError: (state) => state.discounts.isError,
      totalRows: (state) => state.discounts.totalRows,
      discounts: (state) => state.discounts.items,
      errorMessage: (state) => state.discounts.errorMessage,
    }),
  },
  mounted() {
    this.actionGetDiscounts();
  },
  methods: {
    ...mapActions('discounts', [
      'searchDiscounts',
      'fetchDiscountsByid',
      'fetchDiscounts',
      'searchDiscountsBydate',
      'reactiveDiscount',
      'deactiveDiscount',
    ]),
    actionRefresh() {
      this.actionGetDiscounts();
    },
    actionRefreshData() {
      if (this.filter.length == 0) {
        this.actionRefresh();
      }
    },
    actionGetDiscounts() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
      };
      if(this.filter) payload.search = Utils.encodeUrlWithBase64(this.filter);
      if(this.discountStatus) payload.discountStatus = this.discountStatus?.value;
      if(this.discountType) payload.discountType = this.discountType?.value;
      if(this.range?.start) payload.from = moment(this.range?.start).format('YYYY-MM-DD');
      if(this.range?.end) payload.to = moment(this.range?.end).format('YYYY-MM-DD');
      this.searchDiscountsBydate(payload);
    },
    asyncFind(query) {
      this.typing = 'You are typing';
      if (query.length < 3) {
        clearTimeout(this.debounce);
        this.typing = false;
        return;
      }
      this.isLoadingSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.currentPage = 1;
        let payload = {
          page: 1,
          limit: this.perPage,
        };
        if(this.filter) payload.search = Utils.encodeUrlWithBase64(this.filter);
        if(this.discountStatus) payload.discountStatus = this.discountStatus?.value;
        if(this.discountType) payload.discountType = this.discountType?.value;
        if(this.range?.start) payload.from = moment(this.range?.start).format('YYYY-MM-DD');
        if(this.range?.end) payload.to = moment(this.range?.end).format('YYYY-MM-DD');
        this.searchDiscountsBydate(payload);
        this.typing = false;
      }, 1000);
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    asyncClearFind() {
      this.range = null;
      this.discountStatus = null;
      this.discountType = null;
      this.filter = null;
      this.actionGetDiscounts();
    },
    searchByValidTo() {
      this.currentPage = 1;
      let payload = {
        page: 1,
        limit: this.perPage,
      };
      if(this.filter) payload.search = Utils.encodeUrlWithBase64(this.filter);
      if(this.discountStatus) payload.discountStatus = this.discountStatus?.value;
      if(this.discountType) payload.discountType = this.discountType?.value;
      if(this.range?.start) payload.from = moment(this.range?.start).format('YYYY-MM-DD');
      if(this.range?.end) payload.to = moment(this.range?.end).format('YYYY-MM-DD');
      this.searchDiscountsBydate(payload);
    },

    Reactivate(payload) {
      this.reactiveDiscount(payload)
        .then((res) => {
          if (res.status == 201) {
            this.$swal('Success', 'Status successfully updated', 'success');
          } else {
            this.$swal('Failed', 'Update status failed', 'error');
          }
        })
        .catch((err) => {
          console.log(err);
          this.$swal('Failed', 'Ops. Something went wrong', 'error');
        })
        .finally(() => this.actionGetDiscounts());
    },

    Deactivate(payload) {
      this.reactiveDiscount(payload)
        .then((res) => {
          if (res.status == 201) {
            this.$swal('Success', 'Status successfully updated', 'success');
          } else {
            this.$swal('Failed', 'Update status failed', 'error');
          }
        })
        .catch((err) => {
          console.log(err);
          this.$swal('Failed', 'Ops. Something went wrong', 'error');
        })
        .finally(() => this.actionGetDiscounts());
    },
    alertMsg(msg, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: msg,
        timer: 10000,
        text: this.message,
      });
    },
  },
};
</script>

<style scoped>
.discount {
  font-family: 'Avenir Next', 'effra', Helvetica, Arial, sans-serif;
}
input::placeholder {
  font-style: italic;
}
</style>
