var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-table', {
    attrs: {
      "sticky-header": "550px",
      "responsive": "xl",
      "show-empty": "",
      "busy": _vm.isLoading,
      "hover": "",
      "isLoading": _vm.isLoading,
      "fields": _vm.fields,
      "items": _vm.discounts
    },
    scopedSlots: _vm._u([{
      key: "head()",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(row.label) + " ")])];
      }
    }, {
      key: "cell(no)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s((_vm.currentpage - 1) * _vm.perpage + row.index + 1) + " ")];
      }
    }, {
      key: "cell(name)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "hoverEdit",
          on: {
            "click": function ($event) {
              return _vm.actionEdit(row.item);
            }
          }
        }, [_vm._v(" " + _vm._s(row.item.name ? row.item.name : '') + " ")])];
      }
    }, {
      key: "cell(discount_type)",
      fn: function (row) {
        return [_c('div', [row.item.discount_type === 1 ? _c('span', {
          staticClass: "badge badge-info p-1"
        }, [_vm._v(" " + _vm._s(_vm.promotionType(row.item.discount_type)) + " ")]) : row.item.discount_type === 2 ? _c('span', {
          staticClass: "badge badge-secondary p-1"
        }, [_vm._v(" " + _vm._s(_vm.promotionType(row.item.discount_type)) + " ")]) : row.item.discount_type === 3 ? _c('span', {
          staticClass: "badge badge-success p-1"
        }, [_vm._v(" " + _vm._s(_vm.promotionType(row.item.discount_type)) + " ")]) : row.item.discount_type === 4 ? _c('span', {
          staticClass: "badge badge-secondary p-1"
        }, [_vm._v(" " + _vm._s(_vm.promotionType(row.item.discount_type)) + " ")]) : row.item.discount_type === 5 ? _c('span', {
          staticClass: "badge badge-warning p-1"
        }, [_vm._v(" " + _vm._s(_vm.promotionType(row.item.discount_type)) + " ")]) : _vm._e()])];
      }
    }, {
      key: "cell(valid_from)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "10em"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(row.item.valid_from)) + " ")])];
      }
    }, {
      key: "cell(valid_to)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "10em"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(row.item.valid_to)) + " ")])];
      }
    }, {
      key: "cell(discount_rule)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.discountRuleStr[row.item.discount_rule]) + " ")];
      }
    }, {
      key: "cell(predefined_group)",
      fn: function (row) {
        return [row.item.predefined_group ? _c('div', [_vm._v(" " + _vm._s(_vm.predefinedGroup(row.item.predefined_group)) + " ")]) : _c('div', [_c('div', {
          staticClass: "hoverEdit",
          on: {
            "click": function ($event) {
              return _vm.actionSpecified(row.item);
            }
          }
        }, [_vm._v(" Specified Offer ")])])];
      }
    }, {
      key: "cell(is_active)",
      fn: function (row) {
        return [row.item.is_active == true ? _c('div', {
          on: {
            "click": function ($event) {
              return _vm.actionDeactive(row.item.id);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-check fa-lg fa-beat text-primary hover-check fa-hover-hidden"
        }), _c('i', {
          staticClass: "fa fa-times fa-lg fa-beat text-danger hover-times fa-hover-show fa-2x"
        })]) : _vm._e(), row.item.is_active == false ? _c('div', {
          on: {
            "click": function ($event) {
              return _vm.actionReactive(row.item.id);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-check fa-lg fa-beat text-primary hover-check fa-hover-show fa-2x"
        }), _c('i', {
          staticClass: "fa fa-times fa-lg fa-beat text-danger hover-times fa-hover-hidden"
        })]) : _vm._e()];
      }
    }, {
      key: "cell(status)",
      fn: function (row) {
        return [_vm.stillActive(row.item.valid_from, row.item.valid_to) && row.item.is_active ? _c('div', {
          staticClass: "badge badge-info p-1"
        }, [_vm._v(" Promo Currently Active ")]) : _vm.stillActive(row.item.valid_from, row.item.valid_to) && row.item.is_active ? _c('div', {
          staticClass: "badge badge-danger p-1"
        }, [_vm._v(" Promo Already Expired ")]) : _vm.formatDate(row.item.valid_to) >= _vm.datenow && row.item.is_active ? _c('div', {
          staticClass: "badge badge-warning p-1"
        }, [_vm._v(" Promo Is Not Yet Active ")]) : row.item.is_active == false ? _c('div', [_c('div', {
          staticClass: "badge badge-danger p-1"
        }, [_vm._v(" Promo Inactive ")])]) : _vm._e()];
      }
    }, {
      key: "cell(created)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "10em"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(row.item.created)) + " ")])];
      }
    }, {
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center text-dark my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle",
          attrs: {
            "variant": "dark",
            "type": "grow",
            "label": "Loading .."
          }
        }), _vm._v(" Loading .. ")], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }